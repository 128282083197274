import axios from 'axios';
import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    };
};

export const getProducts = async (customerId: string = null) => {
    const res: any = await axios
        .get(
            `/products${
                customerId
                    ? `?customerId=${customerId}&limit=5000`
                    : '?limit=5000'
            }`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getProductsVisibility = async (customerId: string = null) => {
    const res: any = await axios
        .get(`/products/visibility/${customerId}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getProduct = async (id: string) => {
    const res = await axios.get(`/products/${id}`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const updateProduct = async (product: any) => {
    const id = product._id;
    delete product._id;
    const res: any = await axios
        .put(`/products/${id}`, product, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const updateProductCategory = async (product: any) => {
    const id = product._id;
    delete product._id;
    const res: any = await axios
        .put(`/products/${id}/category`, product, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const updateProductVisibility = async (
    productId: string,
    customerId: string,
) => {
    const res: any = await axios
        .put(`/products/visibility/${productId}/${customerId}`, {}, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const duplicateProduct = async (productId: any) => {
    const res: any = await axios
        .get(`/products/duplicate/${productId}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const addProductImage = async (id: string, image: any) => {
    const res: any = await axios
        .post(`/products/${id}/image`, image, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const deleteProduct = async (id: string) => {
    const res: any = await axios
        .delete(`/products/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getRecipe = async (productId: string) => {
    const res: any = await axios
        .get(`/products/ingredients/${productId}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const createProduct = async (ingredient: any) => {
    const res = await axios
        .post('/products', ingredient, config())
        .catch(errorHandler);
    return res.data;
};
