import React, { useEffect, useState } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { useNavigate, useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/hooks';

import {
    MdCancel,
    MdCheckCircle,
    MdEmail,
    MdOutlineError,
    MdSend,
    MdSimCardDownload,
} from 'react-icons/md';
import Card from 'components/card';
import {
    addTransaction,
    downloadInvoice,
    generateCreditNote,
} from 'interfaces/invoice';
import CreateButton from 'components/buttons/CreateButton';
import InvoiceMenu from './invoiceMenu';
import ExtraMenu from './extraMenu';
import invoiceImg from 'assets/img/invoice.png';
import SearchIcon from 'components/icons/SearchIcon';
import Select from 'components/fields/Select';
import CreditNoteModal from './CreditNoteModal';
import MarkAsPaidModal from './MarkAsPaidModal';
import GoBack from 'components/actions/GoBack';
import EmailsHistoryModal from './emailsHistoryModal';
import CreditNotePartialModal from './CreditNotePartialModal';
import PaiementExportModal from './PaiementExportModal';
import SimpleInvoiceExportModal from './SimpleInvoiceExportModal';

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function InvoicesTable(props: {
    tableData: any;
    onOpen: any;
    onEmailSenderModalOpen: any;
    invoicesMeta: any;
    onFilter: any;
    onRefresh: any;
    onCreateCreditNote: any;
}) {
    const {
        onOpen,
        onEmailSenderModalOpen,
        invoicesMeta,
        onFilter,
        onRefresh,
        onCreateCreditNote,
    } = props;

    const params = useParams();
    const { isOpen, onOpen: onOpenCreditNote, onClose } = useDisclosure();
    const {
        isOpen: isOpenCreditNotePartial,
        onOpen: onOpenCreditNotePartial,
        onClose: onCloseCreditNotePartial,
    } = useDisclosure();

    const {
        isOpen: isOpenMarkAsPaid,
        onOpen: onOpenMarkAsPaid,
        onClose: OnCloseMarkAsPaid,
    } = useDisclosure();

    const {
        isOpen: isOpenEmailsHistory,
        onOpen: onOpenEmailsHistory,
        onClose: OnCloseEmailHistory,
    } = useDisclosure();

    const {
        isOpen: isPaiementExportModalOpen,
        onOpen: onPaiementExportModalOpen,
        onClose: onPaiementExportModalClose,
    } = useDisclosure();

    const {
        isOpen: isSimpleExportModalOpen,
        onOpen: onSimpleExportModalOpen,
        onClose: onSimpleExportModalClose,
    } = useDisclosure();

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [data, setData] = React.useState(() => []);

    // Data saved for modals
    const [creditNoteToCreate, setCreditNoteToCreate] = useState(null);
    const [invoiceToMarkAsPaid, setInvoiceToMarkAsPaid] = useState(null);

    const nav = useNavigate();

    useEffect(() => {
        const tableData = props.tableData.map((invoice: any) => {
            const date = invoice.invoicedAt || invoice.updatedAt;
            return {
                ...invoice,
                customer: invoice.customer.company,
                n: {
                    invoiceNumber: invoice.invoiceNumber,
                    date: date.split('T')[0].split('-').reverse().join('/'),
                },
                title: `${invoice.invoiceLines.length} commandes`,
            };
        });
        setData(tableData);
    }, [props.tableData]);

    const fetchInvoice = async (id: string) => {
        try {
            const blob = await downloadInvoice(id);
            if (!blob) return;

            const invoice = data.find((invoice: any) => invoice._id === id);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${invoice?.to.company}-${invoice?.invoiceNumber}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const addInvoiceTransaction = async (
        invoiceId: string,
        transaction: any,
    ) => {
        const res = await addTransaction(invoiceId, transaction);
        if (!res || !res.data) return;
        OnCloseMarkAsPaid();
        onRefresh();
    };

    const isOpenEmailsHistoryModal = (invoice: any) => {
        setInvoiceToMarkAsPaid(invoice);
        onOpenEmailsHistory();
    };

    const openTransactionModal = async (selectedInvoice: any) => {
        setInvoiceToMarkAsPaid(selectedInvoice);
        onOpenMarkAsPaid();
    };

    const openCreditNoteModal = async (invoiceId: string) => {
        setCreditNoteToCreate(invoiceId);
        onOpenCreditNote();
    };

    const createCreditNote = async (
        creditNoteAmount: number = null,
        vat: number = null,
    ) => {
        const res = await generateCreditNote(
            creditNoteToCreate,
            creditNoteAmount,
            vat,
        );
        if (!res) return;
        setCreditNoteToCreate(null);
        onClose();
        onCloseCreditNotePartial();

        // Used to update the table data
        onFilter(null, 'all');
    };

    const columns = [
        columnHelper.accessor('n', {
            id: 'n',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    N° Facture
                </p>
            ),
            cell: (info) => (
                <>
                    <p className="text-sm text-navy-700 dark:text-white">
                        #{info.getValue()['invoiceNumber']}
                    </p>
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {info.getValue()['date']}
                    </p>
                </>
            ),
        }),
        columnHelper.accessor('customer', {
            id: 'customer',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Client
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Statut
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center">
                    {info.getValue() === 'draft' && (
                        <>
                            <MdOutlineError className="text-amber-500 me-1 dark:text-amber-300" />
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                Brouillon
                            </p>
                        </>
                    )}
                    {info.getValue() === 'generated' && (
                        <div className="flex bg-red-400 rounded-xl p-1 px-3 gap-2">
                            {/* <MdOutlineError className="text-white me-1 dark:text-amber-300" /> */}
                            <p className="text-xs font-bold text-white dark:text-white">
                                Non envoyée
                            </p>
                        </div>
                    )}
                    {info.getValue() === 'sent' && (
                        <div className="flex bg-amber-500 rounded-xl p-1 px-3 gap-1">
                            {/* <MdOutlineError className="text-white me-1 dark:text-amber-300" /> */}
                            <MdEmail className="text-white me-1 dark:text-white" />
                            <p className="text-xs font-bold text-white">
                                Envoyée
                            </p>
                        </div>
                    )}
                    {info.getValue() === 'paid' && (
                        <div className="flex bg-green-500 rounded-xl p-1 px-3 gap-1">
                            <MdCheckCircle className=" text-white" />
                            <p className="text-xs font-bold text-white dark:text-white">
                                Payée
                            </p>
                        </div>
                    )}
                    {info.getValue() === 'canceled' && (
                        <div className="flex bg-red-400 rounded-xl p-1 px-3 gap-2">
                            {/* <MdOutlineError className="text-white me-1 dark:text-amber-300" /> */}
                            <p className="text-xs font-bold text-white dark:text-white">
                                Annulée
                            </p>
                        </div>
                    )}
                    {info.getValue() === 'credit-note' && (
                        <div className="flex bg-blue-400 rounded-xl p-1 px-3 gap-2">
                            {/* <MdOutlineError className="text-white me-1 dark:text-toola-300" /> */}
                            <p className="text-xs font-bold text-white">
                                Facture d'avoir
                            </p>
                        </div>
                    )}
                    {/* <MdCancel className="text-red-500 me-1 dark:text-red-300" /> */}
                </div>
            ),
        }),
        columnHelper.accessor('leftToPay', {
            id: 'leftToPay',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Reste à payer
                </p>
            ),
            cell: (info) => (
                <>
                    {info.getValue() === 0 && (
                        <p className="text-green-500 text-sm font-bold dark:text-white">
                            {info.getValue() >= 0
                                ? info.getValue().toFixed(2)
                                : '-- --'}{' '}
                            €
                        </p>
                    )}
                    {info.getValue() < 0 && (
                        <p className="text-navy-700 text-sm font-bold dark:text-white">
                            {info.getValue() >= 0
                                ? info.getValue().toFixed(2)
                                : '-- --'}{' '}
                            €
                        </p>
                    )}
                    {info.getValue() > 0 && (
                        <p className="text-red-500 text-sm font-bold dark:text-white">
                            {info.getValue() >= 0
                                ? info.getValue().toFixed(2)
                                : '-- --'}{' '}
                            €
                        </p>
                    )}
                </>
            ),
        }),
        columnHelper.accessor('totalHT', {
            id: 'totalHT',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total HT
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue().toFixed(2)} €
                </p>
            ),
        }),
        columnHelper.accessor('totalTTC', {
            id: 'totalTTC',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total TTC
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue().toFixed(2)} €
                </p>
            ),
        }),
        columnHelper.accessor('_id', {
            id: '_id',
            header: () => {},
            cell: (info) => (
                <div className="flex items-center gap-5">
                    <MdSimCardDownload
                        title="Télécharger la facture"
                        className="text-toola-500 h-6 w-6 hover:cursor-pointer hover:text-toola-700"
                        onClick={() => fetchInvoice(info.getValue())}
                    />
                    <MdSend
                        title="Envoyer par mail"
                        className="text-toola-500 h-6 w-6 hover:cursor-pointer hover:text-toola-700"
                        onClick={() => onEmailSenderModalOpen(info.getValue())}
                    />
                    <InvoiceMenu
                        disabled={['canceled', 'credit-note'].includes(
                            data.find(
                                (invoice: any) =>
                                    invoice._id === info.getValue(),
                            )?.status,
                        )}
                        isLastItem={
                            data.findIndex(
                                (invoice: any) =>
                                    invoice._id === info.getValue(),
                            ) ===
                                data.length - 1 ||
                            data.findIndex(
                                (invoice: any) =>
                                    invoice._id === info.getValue(),
                            ) ===
                                data.length - 2
                        }
                        invoice={data.find(
                            (invoice: any) => invoice._id === info.getValue(),
                        )}
                        openHistory={isOpenEmailsHistoryModal}
                        updateInvoiceStatus={openTransactionModal}
                        createCreditNote={(id: string) =>
                            openCreditNoteModal(id)
                        }
                    />
                </div>
            ),
        }),
    ]; // eslint-disable-next-line
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const SendMassInvoice = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] mb-3 pt-[10px] px-[29px] h-fit bg-blue-200 shadow shadow-3xl border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Envoyer vos factures ✉️
                    </p>
                    <p className="mt-3 mb-3 text-base text-gray-600">
                        Toutes les factures non-envoyées de toutes les périodes
                        confondues seront envoyées. (
                        <b className="text-gray-700">
                            {invoicesMeta && invoicesMeta.totalGenerated}{' '}
                            facture
                            {invoicesMeta &&
                                invoicesMeta.totalGenerated > 1 &&
                                's'}
                        </b>{' '}
                        )
                    </p>
                    <CreateButton
                        value={`Envoyer`}
                        extra="my-auto h-10"
                        onClick={() => onEmailSenderModalOpen('all')}
                    />
                </div>
            </Card>
        );
    };

    return (
        <>
            {invoicesMeta && invoicesMeta.totalGenerated > 0 && (
                <SendMassInvoice />
            )}
            <CreditNoteModal
                isOpen={isOpen}
                onClose={onClose}
                generateCreditNote={createCreditNote}
                generatePartialCreditNote={() => {
                    onClose();
                    onOpenCreditNotePartial();
                }}
            />
            <PaiementExportModal
                isOpen={isPaiementExportModalOpen}
                onClose={onPaiementExportModalClose}
                handleExport={() => {}}
            />
            <SimpleInvoiceExportModal
                isOpen={isSimpleExportModalOpen}
                onClose={onSimpleExportModalClose}
                handleExport={() => {}}
            />
            <CreditNotePartialModal
                isOpen={isOpenCreditNotePartial}
                onClose={onCloseCreditNotePartial}
                generateCreditNote={(amount: number, vat: number) => {
                    createCreditNote(amount, vat);
                }}
            />
            <MarkAsPaidModal
                isOpen={isOpenMarkAsPaid}
                onClose={() => {
                    OnCloseMarkAsPaid();
                    onRefresh();
                }}
                invoice={invoiceToMarkAsPaid}
                onClick={addInvoiceTransaction}
            />
            <EmailsHistoryModal
                isOpen={isOpenEmailsHistory}
                onClose={() => {
                    OnCloseEmailHistory();
                    onRefresh();
                }}
                invoice={invoiceToMarkAsPaid}
                onClick={OnCloseEmailHistory}
            />
            <GoBack />
            <Card extra={'mt-1 w-full px-6 pb-6 sm:overflow-x-auto border'}>
                <div className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        Vos dernières factures
                    </div>
                    <div className="flex items-center gap-4">
                        <CreateButton
                            value="Facturer mes clients"
                            extra="my-auto h-10"
                            onClick={() => nav('/generate-invoice')}
                        />
                        <ExtraMenu
                            onOpen={onOpen}
                            onCreateCreditNote={onCreateCreditNote}
                            onPaiementExportModalOpen={
                                onPaiementExportModalOpen
                            }
                            onSimpleExportModalOpen={onSimpleExportModalOpen}
                        />
                    </div>
                </div>
                <p
                    className="ml-1 text-sm text-toola-500 hover:underline hover:cursor-pointer"
                    onClick={() =>
                        nav(`/unpaid-manager/${params.month}/${params.year}`)
                    }
                >
                    Gestionnaire des impayés
                </p>

                {/* Filters */}
                <div className="mt-2 grid grid-cols-12 gap-5">
                    <div className="mt-4 col-span-3">
                        <div className="flex h-[30px] flex-grow items-center rounded-xl bg-lightPrimary text-xs text-gray-600">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Client, N° Facture, ..."
                                onChange={(e) =>
                                    onFilter(e.target.value || '', null)
                                }
                                className="block w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <p className="text-xs ml-1">Statut</p>
                        <Select
                            extra="!mt-0 pt-[5px] h-[30px] !w-[200px]"
                            options={[
                                { label: 'Tous', value: 'all' },
                                { label: 'Non envoyée', value: 'generated' },
                                {
                                    label: 'Envoyée',
                                    value: 'sent',
                                },
                                { label: 'Payée', value: 'paid' },
                                { label: 'Avoir', value: 'credit-note' },
                                { label: 'Annulée', value: 'canceled' },
                            ]}
                            displayKey="label"
                            onSelect={(e: any) => onFilter(null, e.value)}
                            required={false}
                        />
                    </div>
                </div>

                <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
                    <table className="w-full">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="!border-px !border-gray-400"
                                >
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                onClick={header.column.getToggleSortingHandler()}
                                                className="cursor-pointer border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                                            >
                                                <div className="items-center justify-between text-xs text-gray-200">
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                    {{
                                                        asc: '',
                                                        desc: '',
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[150px] border-white/0 py-3  pr-4"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {table.getRowModel().rows.length === 0 && (
                        <div className="col-span-12 mt-10">
                            <img
                                className="mx-auto flex h-[125px] w-[125px] items-center justify-center"
                                src={invoiceImg}
                                alt=""
                            />
                            <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                Aucune facture disponible
                            </h4>
                            <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                Les filtres appliqués ne correspondent à aucune
                                facture exitante.
                            </p>
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
}
