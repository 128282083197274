import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import { cancelTransaction } from 'interfaces/invoice';
import { useEffect, useState } from 'react';
import { dateToFrenchShort } from 'utils/date';

const MarkAsPaidModal = (props: {
    isOpen: any;
    onClose: any;
    invoice: any;
    onClick: any;
}) => {
    const { isOpen, onClose, invoice, onClick } = props;
    const [transaction, setTransaction] = useState(null);
    const [canceledTransaction, setCanceledTransaction] = useState([]);

    const handleChanges = (e: any) => {
        const { id, value } = e.target;
        setTransaction((prev: any) => ({ ...prev, [id]: value }));
    };

    const cleanPaymentMethod = (paymentMethod: string) => {
        if (paymentMethod === 'credit-note') return 'Avoir';
        return paymentMethod;
    };

    useEffect(() => {
        console.log(invoice);
        setTransaction((prev: any) => ({
            ...prev,
            amount: invoice?.leftToPay,
            date: new Date().toISOString().slice(0, 10),
            paymentMethod: '',
            reference: '',
        }));
    }, [invoice, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        {invoice?.leftToPay > 0 && (
                            <>
                                <h1 className="mb-2 text-xl font-bold">
                                    Marquer comme payé
                                </h1>
                                <p className="mb-5 text-sm">
                                    Reste à payer:{' '}
                                    {invoice?.leftToPay.toFixed(2)}€ sur{' '}
                                    {invoice?.totalTTC.toFixed(2)}€{' '}
                                </p>
                                <div className="grid grid-cols-2 gap-3 mb-3">
                                    <InputField
                                        id="date"
                                        label="Date de paiement*"
                                        type="date"
                                        defaultValue={transaction?.date}
                                        inputSmall={true}
                                        onChange={handleChanges}
                                    />
                                    <InputField
                                        id="amount"
                                        label="Montant payé*"
                                        type="number"
                                        defaultValue={invoice?.leftToPay}
                                        placeholder="1000"
                                        inputSmall={true}
                                        onChange={handleChanges}
                                    />
                                    <SearchSelector
                                        label="Moyen de paiement"
                                        displayKey="name"
                                        value={transaction?.paymentMethod}
                                        inputSmall={true}
                                        options={[
                                            {
                                                _id: 'cash',
                                                name: 'Espèces',
                                            },
                                            {
                                                _id: 'check',
                                                name: 'Chèque',
                                            },
                                            {
                                                _id: 'transfer',
                                                name: 'Virement',
                                            },
                                            {
                                                _id: 'credit-card',
                                                name: 'Carte bancaire',
                                            },
                                            {
                                                _id: 'direct-debit',
                                                name: 'Prélèvement',
                                            },
                                        ]}
                                        onSelect={(e: any) => {
                                            setTransaction({
                                                ...transaction,
                                                paymentMethod: e.name,
                                            });
                                        }}
                                    />
                                    <InputField
                                        id="reference"
                                        label="Référence de paiement"
                                        type="text"
                                        placeholder="RF-2021-0001"
                                        inputSmall={true}
                                        onChange={handleChanges}
                                    />
                                </div>
                            </>
                        )}
                        {invoice &&
                            invoice.transactions &&
                            invoice.transactions.length > 0 && (
                                <>
                                    <h1 className="text-xl font-bold">
                                        Paiements précédents
                                    </h1>
                                    <div className="mt-3 max-h-[150px] overflow-x-scroll">
                                        <table className="w-full bg-gray-100 rounded-xl border-separate">
                                            <thead>
                                                <tr className="bg-white text-xs font-medium rounded-xl">
                                                    <th className="py-3">
                                                        Date
                                                    </th>
                                                    <th className="py-3">
                                                        Référence
                                                    </th>
                                                    <th className="py-3">
                                                        Méthode
                                                    </th>
                                                    <th className="py-3">
                                                        Montant
                                                    </th>
                                                    <th className="py-3">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice &&
                                                    invoice.transactions
                                                        .filter(
                                                            (
                                                                transaction: any,
                                                            ) => {
                                                                return !canceledTransaction.includes(
                                                                    transaction.id,
                                                                );
                                                            },
                                                        )
                                                        .map(
                                                            (
                                                                transaction: any,
                                                                index: number,
                                                            ) => (
                                                                <tr
                                                                    key={index}
                                                                    className={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                            ? 'bg-gray-50 text-xs text-center'
                                                                            : 'bg-white text-xs text-center'
                                                                    }
                                                                >
                                                                    <td className="py-3">
                                                                        {dateToFrenchShort(
                                                                            transaction.date,
                                                                        )}
                                                                    </td>
                                                                    <td className="py-3">
                                                                        {transaction.reference ||
                                                                            'N/A'}
                                                                    </td>
                                                                    <td className="py-3">
                                                                        {cleanPaymentMethod(
                                                                            transaction.paymentMethod ||
                                                                                'N/A',
                                                                        )}
                                                                    </td>
                                                                    <td className="py-3">
                                                                        {
                                                                            transaction.amount
                                                                        }
                                                                        €
                                                                    </td>
                                                                    <td className="py-3">
                                                                        {transaction?.paymentMethod !==
                                                                        'credit-note' ? (
                                                                            <button
                                                                                className="text-red-500 px-2"
                                                                                onClick={async () => {
                                                                                    await cancelTransaction(
                                                                                        invoice.id,
                                                                                        transaction.id,
                                                                                    );
                                                                                    setCanceledTransaction(
                                                                                        (
                                                                                            prev: any,
                                                                                        ) => [
                                                                                            ...prev,
                                                                                            transaction.id,
                                                                                        ],
                                                                                    );
                                                                                    onClose();
                                                                                }}
                                                                            >
                                                                                Annuler
                                                                            </button>
                                                                        ) : (
                                                                            'Indisponible'
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        {invoice?.leftToPay > 0 ? (
                            <div className="mt-5 flex gap-2 mx-auto">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Annuler
                                </button>
                                <button
                                    className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                    onClick={() =>
                                        onClick(invoice.id, transaction)
                                    }
                                >
                                    {'Enregistrer la transaction'}
                                </button>
                            </div>
                        ) : (
                            <div className="mt-5 flex gap-2 mx-auto">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Revenir aux factures
                                </button>
                            </div>
                        )}
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default MarkAsPaidModal;
