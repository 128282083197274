import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SearchSelector from 'components/fields/SearchSelector';
import { productColorList } from 'utils/lib';
import { cleanStr } from 'utils/tools';
import ProductCard from './components/productCard';
import {
    getProductsVisibility,
    updateProductVisibility,
} from 'interfaces/products';
import GoBack from 'components/actions/GoBack';

const EditCustomerShopVisibility = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [products, setProducts] = useState(null);
    const [advancedMode, setAdvancedMode] = useState(false);
    const [meta, setMeta] = useState(null);
    const [category, setCategory] = useState('all');
    const [visibility, setVisibility] = useState('all');
    const [color, setColor] = useState('all');

    const initProducts = async (customerId: string) => {
        const res = await getProductsVisibility(customerId);
        if (res.error) {
            navigate('/auth/login');
            return;
        }
        setProducts(res.products);
        setMeta(res.meta);
    };

    const updateVisibility = async (productId: string, customerId: string) => {
        const res = await updateProductVisibility(productId, customerId);
        if (res) {
            setProducts((prev: any) =>
                prev.map((e: any) =>
                    e._id === productId ? { ...e, visible: !e.visible } : e,
                ),
            );
        }
    };

    useEffect(() => {
        initProducts(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SkelettonProduct = () => {
        return (
            <div
                className={`animate-pulse relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
            >
                <div className="grid grid-cols-12">
                    <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                        <div className="h-[80px] w-[80px] rounded-xl bg-gray-200"></div>
                    </div>
                    <div className="col-span-8 ml-4">
                        <div className="mt-2 w-[99%] h-4 bg-navy-700/50 rounded-full"></div>
                        <div className="my-1 w-[60px] h-2 bg-gray-600 rounded-full"></div>
                        <p className=" mb-1 text-sm font-medium text-gray-600">
                            <div className="w-5 h-3 bg-gray-600 rounded-full"></div>
                        </p>
                        <div className="w-[60px] h-5 bg-gray-600  rounded-full">
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <GoBack />
            {products &&
            products?.filter((e: any) => !e.visible).length === 0 &&
            !advancedMode ? (
                <div className="mt-4 bg-white col-span-12 border p-10 rounded-xl">
                    <div className="font-large items-center justify-center rounded-xl bg-white py-2 text-base text-gray-700 dark:!bg-navy-900 dark:text-white">
                        <h1 className="text-center font-bold">
                            Boutique {meta?.customer?.name}
                        </h1>
                        <p className="mt-5 text-center">
                            L’affichage des produits pour ce client est
                            actuellement géré en fonction des{' '}
                            <span
                                className="text-toola-500 hover:cursor-pointer hover:underline"
                                onClick={() =>
                                    window.open('/b2b-shop/display', '_blank')
                                }
                            >
                                paramètres produits
                            </span>{' '}
                            existants.{' '}
                        </p>
                        <p className="mt-4 text-center">
                            En mode personnalisé, ces réglages n’affecteront
                            plus la boutique de ce client. Seuls les produits
                            visibles sur cette interface lui seront accessibles.
                        </p>
                        <button
                            className="linear mt-5 flex mx-auto items-center justify-center rounded-xl bg-[#0260CB] px-10 py-2 text-sm font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={() => setAdvancedMode(true)}
                        >
                            Utiliser le mode personnalisé pour{' '}
                            {meta?.customer?.name}
                        </button>
                    </div>
                </div>
            ) : (
                <Card
                    extra={
                        'mt-3 bg-white w-full h-full sm:overflow-auto px-6 border'
                    }
                >
                    <p className="mt-5">
                        <span className="font-bold">
                            {meta?.customer?.name}
                        </span>{' '}
                        {products?.filter((p: any) => p.visible)?.length}{' '}
                        produits visibles et{' '}
                        {products?.filter((p: any) => !p.visible)?.length}{' '}
                        cachés
                    </p>
                    {/* Search bar */}
                    <div className="flex max-w-full justify-between items-center gap-3 rounded-xl pt-3">
                        <div className="flex h-[38px] w-[400px] rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Recherche...."
                                onChange={(e) => setSearch(e.target.value)}
                                className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                            />
                        </div>
                    </div>
                    {/* Product type and colors */}
                    <div className="flex justify-between">
                        <div className="mt-4 flex gap-2 overflow-x-auto h-7">
                            <p className="flex my-auto text-sm underline">
                                Type de produits:{' '}
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'all' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('all')}
                            >
                                Tout
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'brut' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('brut')}
                            >
                                Produit brut
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'transformed' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('transformed')}
                            >
                                Produit transformé
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'assembled' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('assembled')}
                            >
                                Produit assemblé
                            </p>
                        </div>
                        <div className="pt-3 z-50 w-[180px] text-sm">
                            <SearchSelector
                                displayKey="name"
                                options={[
                                    {
                                        name: 'Toutes les couleurs',
                                        id: 'all',
                                        _id: 'all',
                                    },
                                    ...productColorList.filter(
                                        (e: any) =>
                                            products &&
                                            products.some(
                                                (s: any) =>
                                                    s.colorCode === e.id,
                                            ),
                                    ),
                                ]}
                                value={color}
                                onSelect={(e: any) => {
                                    setColor(e.id);
                                }}
                                defaultKey="id"
                                canCreate={false}
                                createNew={() => {}}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    {/* Visibility */}
                    <div className="mt-1 flex justify-between">
                        <div className="flex gap-2 overflow-x-auto h-7">
                            <p className="flex my-auto text-sm underline">
                                Visibilité:{' '}
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'all' === visibility && 'font-bold'
                                }`}
                                onClick={() => setVisibility('all')}
                            >
                                Tout
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'visible' === visibility && 'font-bold'
                                }`}
                                onClick={() => setVisibility('visible')}
                            >
                                Visible
                            </p>
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                    'hide' === visibility && 'font-bold'
                                }`}
                                onClick={() => setVisibility('hide')}
                            >
                                Caché
                            </p>
                        </div>
                    </div>
                    {/* Skelleton Loader */}
                    {!products && (
                        <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16,
                            ].map((item, index) => (
                                <SkelettonProduct key={index} />
                            ))}
                        </div>
                    )}
                    {/* Items list */}
                    <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {products &&
                            products
                                .filter(
                                    (e: any) =>
                                        cleanStr(e.name).includes(
                                            cleanStr(search),
                                        ) ||
                                        cleanStr(e.ref).includes(
                                            cleanStr(search),
                                        ),
                                )
                                .filter((e: any) =>
                                    category === 'all'
                                        ? true
                                        : e.productType === category,
                                )
                                .filter((e: any) =>
                                    visibility === 'all'
                                        ? true
                                        : e.visible ===
                                          (visibility === 'visible'),
                                )
                                .filter((e: any) =>
                                    color === 'all'
                                        ? true
                                        : e.colorCode === color,
                                )
                                .reverse()
                                .map((item: any, index: number) => (
                                    <ProductCard
                                        id={item._id}
                                        visible={item.visible}
                                        key={index}
                                        name={item.name}
                                        type={item.type}
                                        alert={item.alert}
                                        price={item.price}
                                        img={item.imageUrl}
                                        productType={item.productType}
                                        reference={item.ref}
                                        color={item.colorCode}
                                        updateVisibility={() =>
                                            updateVisibility(
                                                item._id,
                                                params.id,
                                            )
                                        }
                                    />
                                ))}
                        {products &&
                            products
                                .filter(
                                    (e: any) =>
                                        cleanStr(e.name).includes(
                                            cleanStr(search),
                                        ) ||
                                        cleanStr(e.ref).includes(
                                            cleanStr(search),
                                        ),
                                )
                                .filter((e: any) =>
                                    category === 'all'
                                        ? true
                                        : e.productType === category,
                                )
                                .filter((e: any) =>
                                    visibility === 'all'
                                        ? true
                                        : e.visible ===
                                          (visibility === 'visible'),
                                )
                                .filter((e: any) =>
                                    color === 'all'
                                        ? true
                                        : e.colorCode === color,
                                ).length === 0 && (
                                <div className="mt-3 flex justify-center col-span-12">
                                    <h3>
                                        Aucun produit trouvé avec ces critères
                                    </h3>
                                </div>
                            )}
                    </div>
                </Card>
            )}
        </>
    );
};

export default EditCustomerShopVisibility;
